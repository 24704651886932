import React from 'react';
import Encabezado from '../Secciones/Encabezado';
import Burbuja from '../Secciones/Burbuja.js';
import Principal from '../Secciones/Principal';
import Servicios from '../Secciones/Servicios';
import Nosotros from '../Secciones/Nosotros';
import Clientes from '../Secciones/Clientes';
import Contacto from '../Secciones/Contacto';
import Footer from '../Secciones/Footer';

const Raiz = () => {
    return (
        <div>
            <Encabezado/>
            <Burbuja/>
            <Principal/>
            <Servicios/>
            <Nosotros/>
            <Clientes/>
            <Contacto/>
            <Footer/>
        </div>
    );
};

export default Raiz;
