import React from 'react';
import '../Estilos/burbuja.scss';

const Burbuja = () => {
    return (
        <a
            href="https://wa.me/+523318336644"
            className="whatsapp-bubble"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Contactar por WhatsApp"
        >
            <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                alt="WhatsApp"
            />
        </a>
    );
};

export default Burbuja;
